import { Box, Button, Typography } from '@mui/material';

import { BaseModal } from '../../components/Modals';

interface IProps {
  hideClose?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  onResend: () => void;
  open: boolean;
  sent?: boolean;
}

export function ResendEmailConfirmationModal({
  isLoading,
  onResend,
  open,
  sent,
  onClose,
}: IProps) {
  return (
    <BaseModal
      title="Please verify your email"
      onClose={sent ? onClose : undefined}
      open={open}
    >
      <Box maxWidth={400}>
        <Typography>
          {!sent &&
            "Didn't receive the email? Please click the button below to resend."}
          {sent && 'Verification email has been resent.'}
        </Typography>
        {!sent && (
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button onClick={onResend} disabled={isLoading} sx={{ mt: 2 }}>
              Resend
            </Button>
          </Box>
        )}
      </Box>
    </BaseModal>
  );
}
