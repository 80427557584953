import { SVGProps } from 'react';

export function Homes(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 452 334"
      {...props}
    >
      <path
        d="m317.174 20.759-59.857 41.516h14.964l51.799-36.903-6.906-4.613Z"
        fill="#346478"
      />
      <path
        d="m322.775 22.614-56.429 40.704h115.116l-58.687-40.704Z"
        fill="#E8F8F4"
      />
      <path fill="#54B5BA" d="M374.858 73.819h75.972v260.628h-75.972z" />
      <path fill="#E8F8F4" d="M257.317 73.819h117.41v170.677h-117.41z" />
      <path fill="#9CC1C1" d="M257.317 62.276h117.41v11.532h-117.41z" />
      <path fill="#346478" d="M374.858 62.276h75.972v11.532h-75.972z" />
      <path
        d="M317.238 20.759h75.972l56.403 41.516h-75.972l-56.403-41.516Z"
        fill="#70E0CA"
      />
      <path
        fill="#346478"
        d="M291.978 87.626h16.115v16.145h-16.115zM326.54 87.626h16.115v16.145H326.54z"
      />
      <path
        fill="#E8F8F4"
        d="M388.604 87.626h16.115v16.145h-16.115zM423.087 87.626h16.115v16.145h-16.115zM388.604 122.238h16.115v16.145h-16.115zM423.087 122.238h16.115v16.145h-16.115zM388.604 156.854h16.115v16.145h-16.115zM423.087 156.854h16.115v16.145h-16.115zM388.604 191.421h16.115v16.145h-16.115zM423.087 191.421h16.115v16.145h-16.115z"
      />
      <path
        fill="#346478"
        d="M291.978 122.238h16.115v16.145h-16.115zM291.978 156.854h16.115v16.145h-16.115zM291.978 191.421h16.115v16.145h-16.115zM326.54 122.238h16.115v16.145H326.54zM326.54 156.854h16.115v16.145H326.54zM326.54 191.421h16.115v16.145H326.54z"
      />
      <path fill="#46929C" d="M189.604 239.705h261.833v18.091H189.604z" />
      <path fill="#E8F8F4" d="M189.604 257.796h261.833v76.887H189.604z" />
      <path
        d="m191.47 258.315 41.184 76.113H191.47v-76.113ZM22.485 44.971h57.078v136.641H22.485z"
        fill="#46929C"
      />
      <path fill="#346478" d="M20.808 39.203h57.554v9.226H20.808z" />
      <path
        fill="#E8F8F4"
        d="M78.295 39.203h117.41v9.226H78.295zM78.295 48.436h117.41v113.016H78.295z"
      />
      <path
        d="M138.181 0h-2.273L76.052 39.21h119.713L138.181 0Z"
        fill="#46929C"
      />
      <path d="M139.454 0h-8.15l57.051 39.21h7.41L139.454 0Z" fill="#346478" />
      <path d="M77.986 0h57.93l-57.93 39.21H20.808L77.986 0Z" fill="#70E0CA" />
      <path fill="#E8F8F4" d="M39.315 64.586h18.417v18.451H39.315z" />
      <path
        fill="#346478"
        d="M112.884 64.586h16.115v18.451h-16.115zM145.129 64.586h16.115v18.451h-16.115z"
      />
      <path fill="#E8F8F4" d="M61.887 161.467h174.965v78.419H61.887z" />
      <path
        d="m236.765 156.821 17.266-27.678 33.381 27.678v83.031h-50.647v-83.031Z"
        fill="#46929C"
      />
      <path
        fill="#346478"
        d="M61.887 156.854h225.612v4.613H61.887zM287.412 196.039v43.823h-50.647l50.647-43.823ZM256.419 126.862h-5.756L283 156.846h4.498l-31.079-29.984Z"
      />
      <path
        d="M97.57 126.862h158.85l-19.569 29.984H61.887l35.683-29.984Z"
        fill="#70E0CA"
      />
      <path
        fill="#346478"
        d="M126.331 179.894h18.417v18.451h-18.417zM158.576 179.894h18.417v18.451h-18.417zM202.175 179.894h18.417v18.451h-18.417z"
      />
      <path fill="#E8F8F4" d="M255.22 179.894h18.417v18.451H255.22z" />
      <path fill="#46929C" d="M0 202.982h67.147v131.467H0z" />
      <path fill="#346478" d="M0 189.136h67.147v13.839H0z" />
      <path
        fill="#E8F8F4"
        d="M67.135 189.136h137.828v13.839H67.135zM67.135 202.982h137.828v131.467H67.135z"
      />
      <path
        d="M136.902 126.862h-2.651l-69.797 62.274h139.595l-67.147-62.274Z"
        fill="#46929C"
      />
      <path
        d="M136.902 126.862h-9.719l68.031 62.274h8.835l-67.147-62.274Z"
        fill="#346478"
      />
      <path
        d="M67.147 126.862h68.03l-68.03 62.274H0l67.147-62.274Z"
        fill="#70E0CA"
      />
      <path fill="#E8F8F4" d="M23.002 226.031h27.086v27.136H23.002z" />
      <path
        fill="#346478"
        d="M99.315 226.031h27.086v27.136H99.315zM144.945 226.031h27.086v27.136h-27.086z"
      />
      <path fill="#46929C" d="M248.289 262.319h54.172v72.364h-54.172z" />
      <path fill="#346478" d="M248.289 257.796h54.172v9.045h-54.172z" />
      <path fill="#E8F8F4" d="M302.462 257.796h117.373v85.932H302.462z" />
      <path
        d="M363.376 217.092h-2.229l-58.686 40.704h117.373l-56.458-40.704Z"
        fill="#46929C"
      />
      <path
        d="M364.624 217.092h-7.991l55.937 40.704h7.264l-55.21-40.704Z"
        fill="#346478"
      />
      <path
        d="M304.35 217.092h56.798l-58.687 40.704h-54.172l56.061-40.704Z"
        fill="#70E0CA"
      />
      <path fill="#E8F8F4" d="M266.999 279.368h18.417v18.451h-18.417z" />
      <path
        fill="#346478"
        d="M317.565 279.368h18.417v18.451h-18.417zM349.88 279.368h18.417v18.451H349.88zM382.01 279.368h18.417v18.451H382.01z"
      />
    </svg>
  );
}
