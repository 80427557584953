import {
  AuthorizeDocument,
  AuthorizeMutationVariables,
  ChangePasswordDocument,
  ChangePasswordMutationVariables,
  ConfirmUserDocument,
  ConfirmUserMutationVariables,
  ForgotPasswordDocument,
  ForgotPasswordMutationVariables,
  LoginDocument,
  LoginMutationVariables,
  RegisterUserDocument,
  RegisterUserMutationVariables,
  ResendConfirmDocument,
  ResendConfirmMutationVariables,
  ResetPasswordDocument,
  ResetPasswordMutationVariables,
} from '../../../graphql/generated';

export const authorizeEndpoint = {
  query: (variables: AuthorizeMutationVariables) => ({
    document: AuthorizeDocument,
    variables,
  }),
};

export const changePasswordEndpoint = {
  query: (variables: ChangePasswordMutationVariables) => ({
    document: ChangePasswordDocument,
    variables,
  }),
};

export const confirmUserEndpoint = {
  query: (variables: ConfirmUserMutationVariables) => ({
    document: ConfirmUserDocument,
    variables,
  }),
};

export const forgotPasswordEndpoint = {
  query: (variables: ForgotPasswordMutationVariables) => ({
    document: ForgotPasswordDocument,
    variables,
  }),
};

export const loginEndpoint = {
  query: (variables: LoginMutationVariables) => ({
    document: LoginDocument,
    variables,
  }),
};

export const registerUserEndpoint = {
  query: (variables: RegisterUserMutationVariables) => ({
    document: RegisterUserDocument,
    variables,
  }),
};

export const resendConfirmEndpoint = {
  query: (variables: ResendConfirmMutationVariables) => ({
    document: ResendConfirmDocument,
    variables,
  }),
};

export const resetPasswordEndpoint = {
  query: (variables: ResetPasswordMutationVariables) => ({
    document: ResetPasswordDocument,
    variables,
  }),
};
