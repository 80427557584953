import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IQuoterInput, IQuoterState } from './interfaces';

export const initialQuoterState: IQuoterState = {
  input: {
    selectClient: undefined,
    selectProperty: undefined,
    agreeToDiligencSearch: null,
    underwritingChecks: [],
  },
};

export const quoterSlice = createSlice({
  name: 'quoter',
  initialState: initialQuoterState,
  reducers: {
    reset: () => ({ ...initialQuoterState }),
    setInput: (state, action: PayloadAction<IQuoterInput>) => {
      const { payload } = action;
      state.input = {
        ...state.input,
        ...payload,
      };
    },
  },
});

export const { reset, setInput } = quoterSlice.actions;
