import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../../components/App/PageTitle';
import { Button } from '../../components/Button';
import { ControllerTextField } from '../../components/Inputs';
import { TwoPanelPaper } from '../../components/Paper';
import { QuoteStepperContainer } from '../../components/QuoteStepper/QuoteStepper.container';
import { IStackedListOption, StackedList } from '../../components/StackedList';
import { IAddress } from '../../store/quoter';

import {
  getQuoterSelectPropertySchema,
  IQuoterSelectPropertySchema,
} from './QuoterSelectPropertyPage.schema';

interface IProps {
  initialValues?: IQuoterSelectPropertySchema;
  onSubmit: (values: IQuoterSelectPropertySchema) => void;
  propertyOptions: IStackedListOption<IAddress>[];
  propertyOptionsLoading?: boolean;
}

export function QuoterSelectPropertyPage({
  initialValues,
  onSubmit,
  propertyOptions,
  propertyOptionsLoading,
}: IProps) {
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm<IQuoterSelectPropertySchema>(
    {
      defaultValues: {
        address1: initialValues?.address1 || '',
        address2: initialValues?.address2 || '',
        city: initialValues?.city || '',
        state: initialValues?.state || 'Florida',
        zipcode: initialValues?.zipcode || '',
      },
      resolver: zodResolver(getQuoterSelectPropertySchema()),
    },
  );

  // reset form on property click
  // set new fields to clicked property
  const onAddressSelectedHandler = useCallback(
    (value: IAddress) => {
      reset({
        ...value,
      });
    },
    [reset],
  );

  return (
    <Box>
      <PageTitle
        title={t('pages.quoterSelectPropertyPage.title', 'Add Client Property')}
      />
      {/* Panel */}
      <TwoPanelPaper
        sx={{
          margin: 'auto',
          maxWidth: '960px',
        }}
        leftChildren={
          <Box margin="auto" maxWidth="448px">
            <Typography variant="h2">
              {t(
                'pages.quoterSelectPropertyPage.leftPanelTitle',
                'New Property',
              )}
            </Typography>
            <Box mt={4} component="form" onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing={1}>
                <ControllerTextField
                  control={control}
                  name="address1"
                  label={t('common.labels.address1', 'Street Address')}
                />
                <ControllerTextField
                  control={control}
                  name="city"
                  label={t('common.labels.city', 'City')}
                />
                <ControllerTextField
                  control={control}
                  disabled
                  name="state"
                  label={t('common.labels.state', 'State')}
                />
                <ControllerTextField
                  control={control}
                  name="zipcode"
                  label={t('common.labels.zipcode', 'Zip Code')}
                />
              </Stack>
              <Box mt={3} textAlign="right">
                <Button color="primary" type="submit" variant="contained">
                  {t('common.actions.saveContinue', 'Save and Continue')}
                </Button>
              </Box>
            </Box>
          </Box>
        }
        rightChildren={
          <Box>
            <Typography variant="h2">
              {t(
                'pages.quoterSelectClientPage.rightPanelTitle',
                '{{name}} properties',
                { name: "Rebecca's " },
              )}
            </Typography>
            <Box mt={3}>
              {!propertyOptionsLoading && (
                <StackedList
                  onSelected={onAddressSelectedHandler}
                  options={propertyOptions}
                />
              )}
              {propertyOptionsLoading && (
                <Box>
                  <Skeleton height={80} width="100%" />
                  <Skeleton height={80} width="100%" />
                  <Skeleton height={80} width="100%" />
                </Box>
              )}
            </Box>
          </Box>
        }
      />
      {/* Footer */}
      <QuoteStepperContainer backText={t('pages.quoterSelectClientPage.title')}>
        {/* <ButtonLink
          to="/quoter/select-property"
          color="secondary"
          variant="contained"
        >
          Save and Continue
        </ButtonLink> */}
      </QuoteStepperContainer>
    </Box>
  );
}
