import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../../components/App/PageTitle';
import { QuoteStepperContainer } from '../../components/QuoteStepper/QuoteStepper.container';

export function QuoterCustomizeCoveragePage() {
  const { t } = useTranslation();
  return (
    <Box>
      <PageTitle
        title={t('pages.customizeCoveragePage.title', 'Customize Coverage')}
      >
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
        illo inventore veritatis et quasi.
      </PageTitle>
      <Paper
        sx={{
          margin: 'auto',
          maxWidth: '960px',
          padding: 4,
        }}
      >
        <Typography fontSize="1.0625rem" fontWeight={600} mb={2} variant="h2">
          Option 1
        </Typography>
      </Paper>
      <QuoteStepperContainer backText={t('pages.reviewPropertyDataPage.title')}>
        <Stack direction="row" spacing={2}>
          <Button color="secondary" variant="outlined">
            Download
          </Button>
          <Button color="secondary" variant="outlined">
            Select & bind
          </Button>
          <Button color="secondary" variant="contained">
            Save & Share
          </Button>
        </Stack>
      </QuoteStepperContainer>
    </Box>
  );
}
