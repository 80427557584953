import { Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../../components/App/PageTitle';
import { ButtonLink } from '../../components/ButtonLink';
import { QuoteStepperContainer } from '../../components/QuoteStepper/QuoteStepper.container';

export function QuoterDiligentSearchPage() {
  const { t } = useTranslation();
  return (
    <Box>
      <PageTitle title={t('pages.diligentSearchPage.title', 'Diligent Search')}>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
        illo inventore veritatis et quasi.
      </PageTitle>
      <Paper
        sx={{
          margin: 'auto',
          maxWidth: '960px',
          padding: 4,
        }}
      >
        <Typography fontSize="1.0625rem" fontWeight={600} mb={2} variant="h2">
          What is Diligent Search?
        </Typography>
        <Typography maxWidth="844px" mb={2}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Typography>
        <Typography fontSize="1.0625rem" fontWeight={600} mb={2} variant="h2">
          What we need from you
        </Typography>
        <Typography maxWidth="844px" mb={2}>
          At vero eos et accusamus et iusto odio dignissimos ducimus qui
          blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
          et quas molestias excepturi sint occaecati cupiditate non provident,
          similique sunt in culpa qui officia deserunt mollitia animi, id est
          laborum et dolorum fuga.
        </Typography>
        <Button color="secondary" variant="contained">
          <strong>Download Diligent search form</strong>
        </Button>
      </Paper>
      <QuoteStepperContainer
        backText={t('pages.quoterSelectPropertyPage.title')}
      >
        <ButtonLink
          to="/quoter/underwriting-checks"
          color="secondary"
          variant="contained"
        >
          I Acknowledge, lets proceed
        </ButtonLink>
      </QuoteStepperContainer>
    </Box>
  );
}
