import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Button, Stack, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { QuoteStepperIconItem } from './QuoteStepperIconItem';

const boxShadow = `0px -7px 98px rgba(0, 0, 0, 0.04), 0px -2.92443px 40.9421px rgba(0, 0, 0, 0.0287542), 0px -1.56354px 21.8896px rgba(0, 0, 0, 0.0238443), 0px -0.876509px 12.2711px rgba(0, 0, 0, 0.02), 0px -0.465507px 6.5171px rgba(0, 0, 0, 0.0161557), 0px -0.193708px 2.71191px rgba(0, 0, 0, 0.0112458)`;

const Wrapper = styled(Box)`
  height: 102px;
`;

const FloatingWrapper = styled(Box)`
  position: fixed;
  height: 70px;

  bottom: 0;
  left: 0;
  right: 0;

  background: #fff;
  box-shadow: ${boxShadow};

  padding: 0 40px;
`;

const Container = styled(Box)`
  display: flex;

  align-items: center;
  justify-content: space-between;

  height: 100%;
  width: auto;
`;

interface IProps {
  children?: React.ReactNode;
  backText?: string | null;
  onBack?: () => void;
  clientAddress?: string;
  clientName?: string;
}

export function QuoteStepper({
  children,
  backText,
  clientAddress,
  clientName,
  onBack,
}: IProps) {
  const navigate = useNavigate();
  const backIsCancel = backText === null;

  return (
    <Wrapper>
      <FloatingWrapper>
        <Container>
          <Stack direction="row" spacing={8}>
            <Button
              color="secondary"
              variant="text"
              onClick={() => {
                if (onBack) {
                  onBack();
                }

                if (!backIsCancel) {
                  navigate(-1);
                }
              }}
            >
              {!backIsCancel && (
                <ChevronLeftIcon height="10px" style={{ marginRight: '8px' }} />
              )}
              <Box component="span">{backIsCancel ? 'Cancel' : backText}</Box>
            </Button>
            <QuoteStepperIconItem icon="person">
              {clientName}
            </QuoteStepperIconItem>
            <QuoteStepperIconItem icon="home">
              {clientAddress}
            </QuoteStepperIconItem>
          </Stack>
          <Stack direction="row" spacing={8}>
            {children}
          </Stack>
        </Container>
      </FloatingWrapper>
    </Wrapper>
  );
}
