import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMockDataQuery } from '../../hooks';
import { useQuoterStore } from '../../hooks/useQuoterStore';
import { IAddress } from '../../store/quoter';

import { QuoterSelectPropertyPage } from './QuoterSelectPropertyPage';
import { IQuoterSelectPropertySchema } from './QuoterSelectPropertyPage.schema';

const mockProperties = [
  {
    label: '2425 Sunshine Ln',
    subLabel: 'Florida, USA, 12345',
    value: {
      address1: '2425 Sunshine Ln',
      address2: '',
      city: 'Fort Lauderdale',
      state: 'Florida',
      zipcode: '12345',
    } as IAddress,
  },
  {
    label: '7893 Brookside Rd',
    subLabel: 'Florida, USA, 12234',
    value: {
      address1: '7893 Brookside Rd',
      address2: '',
      city: 'Miami',
      state: 'Florida',
      zipcode: '12234',
    } as IAddress,
  },
  {
    label: '23004 Quiet Meadows St',
    subLabel: 'Florida, USA, 26770',
    value: {
      address1: '23004 Quiet Meadows St',
      address2: '',
      city: 'Orlando',
      state: 'Florida',
      zipcode: '26770',
    } as IAddress,
  },
];

export function QuoterSelectPropertyPageContainer() {
  const navigate = useNavigate();
  const { input, setInput } = useQuoterStore();
  const { data: clientPropertiesReponse, isLoading: propertiesLoading } =
    useMockDataQuery({
      mockResponse: mockProperties,
      mockDelay: 470,
    });

  const onSubmit = useCallback(
    (value: IAddress) => {
      setInput({
        selectProperty: value,
      });

      navigate('/quoter/diligent-search');
    },
    [navigate, setInput],
  );

  return (
    <QuoterSelectPropertyPage
      propertyOptions={clientPropertiesReponse || []}
      propertyOptionsLoading={propertiesLoading}
      initialValues={input?.selectProperty as IQuoterSelectPropertySchema}
      onSubmit={onSubmit}
    />
  );
}
