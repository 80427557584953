import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';

interface IProps {
  children: React.ReactNode;
  to?: string;
}

const navLinkActiveStyle = {
  color: 'grey.80',
};

const navLinkHoverStlye = {
  backgroundColor: 'transparent',
};

export function NavLinks({ children, to }: IProps) {
  return (
    <Button
      {...{
        component: NavLink,
        to,
        style: (({ isActive }: any) =>
          isActive ? navLinkActiveStyle : undefined) as any,
      }}
      disableElevation
      disableRipple
      disableTouchRipple
      sx={{
        color: 'grey.80',
        '&:hover': navLinkHoverStlye,
      }}
      variant="text"
    >
      {children}
    </Button>
  );
}
