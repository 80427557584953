import {
  GetQuotesDocument,
  GetQuotesQueryVariables,
} from '../../../graphql/generated';

export const getQuotesEndpoint = {
  query: (variables: GetQuotesQueryVariables) => ({
    document: GetQuotesDocument,
    variables,
  }),
};
