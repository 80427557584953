import { styled, FormLabel, Typography, Box } from '@mui/material';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.0625rem',
  fontWeight: 600,
  color: 'secondary.main',
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
}));

export const RadioGroupWrapper = styled(Box)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.grey[10],
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: '0.9375rem',
  padding: theme.spacing(1),
}));
