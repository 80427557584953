import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../store/configureStore';
import { IQuoterInput, reset, setInput } from '../store/quoter';

export function useQuoterStore() {
  const dispatch = useDispatch();

  const setInputEventHandler = useCallback(
    (payload: IQuoterInput) => {
      dispatch(setInput(payload));
    },
    [dispatch],
  );

  const resetEventHandler = useCallback(() => {
    reset();
    dispatch(reset());
  }, [dispatch]);

  return {
    input: useSelector((state: RootState) => state.quoter.input),
    setInput: setInputEventHandler,
    reset: resetEventHandler,
  };
}
