import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

interface IProps {
  children?: React.ReactNode;
  onClose?: () => void;
  open?: boolean;
  title?: string;
}

export function BaseModal({ children, open = false, onClose, title }: IProps) {
  return (
    <Dialog
      PaperProps={{
        sx: {
          padding: 3,
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <SendIcon color="success" sx={{ height: '100%', mr: 1 }} />
          <Typography fontSize="1.75rem" fontWeight={600} variant="h3">
            {title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {onClose && (
        <DialogActions>
          <Button onClick={onClose} variant="text">
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
