import { QuoteStepper } from './QuoteStepper';

interface IProps {
  children?: React.ReactNode;
  backText?: null | string;
  onBack?: () => void;
}

export function QuoteStepperContainer({
  backText = 'Add Your Client',
  onBack,
  children,
}: IProps) {
  const clientAddress = '2345 Sunshine Meadows, Houston Tx 77022';
  const clientName = 'Rebecca Gemeinhardt';

  return (
    <QuoteStepper
      backText={backText}
      clientAddress={clientAddress}
      clientName={clientName}
      onBack={onBack}
    >
      {children}
    </QuoteStepper>
  );
}
