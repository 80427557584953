import { Stack, Typography } from '@mui/material';

import { Home } from '../SVGs/Home';
import { Person } from '../SVGs/Person';

const icons = {
  home: Home,
  person: Person,
};

interface IProps {
  children: React.ReactNode;
  icon: 'person' | 'home';
}

export function QuoteStepperIconItem({ children, icon }: IProps) {
  const Icon = icons[icon];

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Icon color="#76BEBE" height={24} width={24} />
      <Typography color="#3F5F69">{children}</Typography>
    </Stack>
  );
}
