import {
  CurrentUserDocument,
  CurrentUserQueryVariables,
  GetUsersDocument,
  GetUsersQuery,
  GetUsersQueryVariables,
} from '../../../graphql/generated';
import { getGenericProvidedTages } from '../../../helpers';
import { CacheTag } from '../CacheTags';

export const currentUserEndpoint = {
  query: (variables: CurrentUserQueryVariables) => ({
    document: CurrentUserDocument,
    variables,
  }),
};

export const getUserEndpoint = {
  query: (variables: GetUsersQueryVariables) => ({
    document: GetUsersDocument,
    variables,
  }),
  providesTags: getGenericProvidedTages<GetUsersQuery>(
    'getUsers',
    CacheTag.USER,
  ),
};
