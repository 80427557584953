import { SVGProps } from 'react';

export function Person(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        d="M16 6.933a5.467 5.467 0 1 0 0 10.933 5.467 5.467 0 0 0 0-10.933Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.847 31.96C6.548 31.367 0 24.448 0 16 0 7.163 7.163 0 16 0s16 7.163 16 16c0 6.69-4.106 12.421-9.936 14.81-.937.442-2.001.739-3.274.948-.384.067-.773.121-1.167.16-.242.029-.492.055-.749.079a.8.8 0 0 1-.22-.01 16.345 16.345 0 0 1-1.308 0 .802.802 0 0 1-.252.006 41.542 41.542 0 0 1-.247-.034Zm-8.409-7.45c.264-.51.616-.98 1.044-1.388a5.588 5.588 0 0 1 3.851-1.522h9.333c1.438 0 2.824.543 3.852 1.522.428.408.78.878 1.044 1.388A12.752 12.752 0 0 0 28.8 16c0-7.07-5.73-12.8-12.8-12.8S3.2 8.93 3.2 16c0 3.267 1.224 6.248 3.238 8.51Z"
        fill="currentColor"
      />
    </svg>
  );
}
