import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

import { theme } from '../../config';
import { ButtonLink } from '../ButtonLink';
import { Logo } from '../SVGs/Logo';
import { Person } from '../SVGs/Person';

import { NavLinks } from './NavLink';

const Wrapper = styled('header')`
  background: white;
  height: 76px;

  border-bottom: 1px solid ${theme.palette.secondary.main};
`;

const Container = styled('nav')`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 ${theme.spacing(2)};
  width: auto;
`;

interface IProps {
  loggedIn: boolean;
}

export function Header({ loggedIn = true }: IProps) {
  return (
    <Wrapper>
      <Container>
        <Box>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Link to="/">
              <Logo height="100%" width="216px" />
            </Link>
            {loggedIn && (
              <NavLinks to="/quoter/select-client">Create Quote</NavLinks>
            )}
            {loggedIn && (
              <NavLinks to="/quotes-contracts">Quotes & Contracts</NavLinks>
            )}
            {loggedIn && <NavLinks to="/clients">Clients</NavLinks>}
          </Stack>
        </Box>
        <Stack alignItems="center" direction="row" spacing={2}>
          <ButtonLink color="secondary" to="/help">
            <Typography fontWeight={700}>Help</Typography>
          </ButtonLink>
          {loggedIn && (
            <Link to="/settings">
              <Person color="#0D1329" height={32} width={32} />
            </Link>
          )}
          {!loggedIn && (
            <ButtonLink to="/login">
              <Stack direction="row" spacing={0.5}>
                <Typography fontWeight={700}>Sign In</Typography>
                <Person color="#0D1329" height={16} width={16} />
              </Stack>
            </ButtonLink>
          )}
        </Stack>
      </Container>
    </Wrapper>
  );
}
