import {
  Components,
  inputLabelClasses,
  outlinedInputClasses,
} from '@mui/material';

import { baseTheme } from './base';

const paperBoxShadow =
  '0px 100px 80px rgba(0, 0, 0, 0.04), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0287542), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0238443), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.02), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0161557), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0112458)';

export const components: Components = {
  MuiCssBaseline: {
    styleOverrides: `
      #root {
        min-height: 100vh;
      }
    `,
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      disableFocusRipple: true,
      disableRipple: true,
      disableTouchRipple: true,
    },
    variants: [
      {
        props: { variant: 'dashed' },
        style: {
          textTransform: 'none',
          border: `2px dashed ${baseTheme.palette.primary.main}`,
          color: baseTheme.palette.primary.main,
        },
      },
    ],
    styleOverrides: {
      contained: {
        color: 'white',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        borderRadius: '8px',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: baseTheme.palette.grey[70],

        [`&.${inputLabelClasses.focused}`]: {
          color: baseTheme.palette.grey[70],
        },
      },
      outlined: {
        color: baseTheme.palette.grey[70],
        lineHeight: '1.75',

        [`&.${inputLabelClasses.shrink}`]: {
          lineHeight: '1.5',
          transform: 'translate(14px, 10px) scale(0.8125)',
        },
      },
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      notched: false,
    },
    styleOverrides: {
      root: {
        borderRadius: '8px',

        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: baseTheme.palette.grey[60],
        },

        [`&.${outlinedInputClasses.focused}`]: {
          [`.${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: '2px',
            borderColor: baseTheme.palette.success.light,
          },
        },
      },
      input: {
        fontWeight: 600,
        padding: '24px 14px 10px 14px',
      },
      notchedOutline: {
        borderColor: baseTheme.palette.grey[30],
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '16px',
      },
      elevation: {
        boxShadow: paperBoxShadow,
      },
      elevation0: {
        boxShadow: 'none',
      },
    },
  },
};
