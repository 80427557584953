import { api } from './api';

export const {
  useAuthorizeMutation,
  useChangePasswordMutation,
  useConfirmUserMutation,
  useCurrentUserQuery,
  useForgotPasswordMutation,
  useGetQuotesQuery,
  useGetUsersQuery,
  useLazyCurrentUserQuery,
  useLazyGetUsersQuery,
  useLoginMutation,
  useRegisterUserMutation,
  useResendConfirmMutation,
  useResetPasswordMutation,
} = api;
