import { createApi } from '@reduxjs/toolkit/query/react';

import {
  AuthorizeMutation,
  AuthorizeMutationVariables,
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
  ConfirmUserMutation,
  ConfirmUserMutationVariables,
  CurrentUserQuery,
  CurrentUserQueryVariables,
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables,
  GetQuotesQuery,
  GetQuotesQueryVariables,
  GetUsersQuery,
  GetUsersQueryVariables,
  LoginMutation,
  LoginMutationVariables,
  RegisterUserMutation,
  RegisterUserMutationVariables,
  ResendConfirmMutation,
  ResendConfirmMutationVariables,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from '../../graphql/generated';

import { CacheTag } from './CacheTags';
import {
  authorizeEndpoint,
  changePasswordEndpoint,
  confirmUserEndpoint,
  currentUserEndpoint,
  forgotPasswordEndpoint,
  getQuotesEndpoint,
  getUserEndpoint,
  loginEndpoint,
  registerUserEndpoint,
  resendConfirmEndpoint,
  resetPasswordEndpoint,
} from './endpoints';
import { graphqlRequestQuery } from './graphqlRequestQuery';

export const api = createApi({
  baseQuery: graphqlRequestQuery,
  reducerPath: 'api',
  tagTypes: [...Object.values(CacheTag)],
  endpoints: (builder) => ({
    authorize: builder.mutation<AuthorizeMutation, AuthorizeMutationVariables>({
      ...authorizeEndpoint,
    }),
    changePassword: builder.mutation<
      ChangePasswordMutation,
      ChangePasswordMutationVariables
    >({ ...changePasswordEndpoint }),
    confirmUser: builder.mutation<
      ConfirmUserMutation,
      ConfirmUserMutationVariables
    >({
      ...confirmUserEndpoint,
    }),
    currentUser: builder.query<CurrentUserQuery, CurrentUserQueryVariables>({
      ...currentUserEndpoint,
    }),
    forgotPassword: builder.mutation<
      ForgotPasswordMutation,
      ForgotPasswordMutationVariables
    >({
      ...forgotPasswordEndpoint,
    }),
    getQuotes: builder.query<GetQuotesQuery, GetQuotesQueryVariables>({
      ...getQuotesEndpoint,
    }),
    getUsers: builder.query<GetUsersQuery, GetUsersQueryVariables>({
      ...getUserEndpoint,
    }),
    login: builder.mutation<LoginMutation, LoginMutationVariables>({
      ...loginEndpoint,
    }),
    registerUser: builder.mutation<
      RegisterUserMutation,
      RegisterUserMutationVariables
    >({
      ...registerUserEndpoint,
    }),
    resendConfirm: builder.mutation<
      ResendConfirmMutation,
      ResendConfirmMutationVariables
    >({
      ...resendConfirmEndpoint,
    }),
    resetPassword: builder.mutation<
      ResetPasswordMutation,
      ResetPasswordMutationVariables
    >({
      ...resetPasswordEndpoint,
    }),
  }),
});
