import { Box, Button } from '@mui/material';

interface IProps {
  onSignOut: () => void;
}

export function SettingsPage({ onSignOut }: IProps) {
  return (
    <Box>
      Settings PAGE! <Button onClick={onSignOut}>Sign Out</Button>
    </Box>
  );
}
