import { Button, ButtonProps } from '@mui/material';
import { Link } from 'react-router-dom';

interface IProps extends ButtonProps {
  children: React.ReactNode;
  to?: string;
}

export function ButtonLink({ children, to, ...props }: IProps) {
  return (
    <Button
      {...{
        component: Link,
        to,
      }}
      variant="text"
      {...props}
    >
      {children}
    </Button>
  );
}
