import { Card, CardProps, Typography } from '@mui/material';

interface IProps extends CardProps {
  children: React.ReactNode;
}

export function BasicInfoCard({ children, title, ...props }: IProps) {
  return (
    <Card
      sx={{ height: '68px', width: '100%', p: 2, borderRadius: '8px' }}
      elevation={0}
      {...props}
    >
      <Typography
        sx={{
          fontSize: '1.0625rem',
          fontWeight: 600,
          color: 'secondary.main',
        }}
      >
        {title}
      </Typography>
      <Typography sx={{ fontSize: '1rem', color: 'grey.80' }}>
        {children}
      </Typography>
    </Card>
  );
}
