import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { User } from '../../graphql/generated';
import { useMockDataQuery } from '../../hooks';
import { useQuoterStore } from '../../hooks/useQuoterStore';

import { QuoterSelectClientPage } from './QuoterSelectClientPage';
import { IQuoterSelectClientSchema } from './QuoterSelectClientPage.schema';

const mockClients = [
  {
    label: 'Gary Norris',
    subLabel: 'gary@arbolmarket.com',
    value: {
      _id: 'cr3f42r3w5',
      email: 'gary@arbolmarket.com',
      profile: {
        firstName: 'Gary',
        lastName: 'Norris',
        phone: '9876542210',
      },
    } as User,
  },
  {
    label: 'Zach Johnson',
    subLabel: 'zach.johnson@arbolmarket.com',
    value: {
      _id: '3rfc454t5',
      email: 'zach.johnson@arbolmarket.com',
      profile: {
        firstName: 'Zach',
        lastName: 'Johnson',
        phone: '5124567890',
      },
    } as User,
  },
];

export function QuoterSelectClientPageContainer() {
  const navigate = useNavigate();
  const { input, setInput, reset } = useQuoterStore();
  const { data: clientPropertiesReponse, isLoading: propertiesLoading } =
    useMockDataQuery({
      mockResponse: mockClients,
      mockDelay: 580,
    });

  const onSubmitHandler = useCallback(
    (value: User) => {
      setInput({
        selectClient: {
          ...value,
        },
      });

      navigate('/quoter/select-property');
    },
    [navigate, setInput],
  );

  const onCancelHandler = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <QuoterSelectClientPage
      clientOptions={clientPropertiesReponse || []}
      clientOptionsLoading={propertiesLoading}
      initialValues={input?.selectClient as IQuoterSelectClientSchema}
      onSubmit={onSubmitHandler}
      onCancel={onCancelHandler}
    />
  );
}
