export const config = {
  api: {
    grapqlEndpoint:
      process.env.REACT_APP_GRAPHQL_ENDPOINT || 'http://localhost:4000/graphql',
  },
  contact: {
    email: process.env.REACT_APP_CONTACT_EMAIL || 'sales@arbolmarket.com',
  },
  mui: {
    licenseKey: 'temp',
  },
};
