import { Box, Typography } from '@mui/material';

import { BaseModal } from '../../components/Modals';

interface IProps {
  email?: string;
  onClose: () => void;
  open: boolean;
}

export function ForgotPasswordSuccessModal({ email, onClose, open }: IProps) {
  return (
    <BaseModal title="Reset email sent" onClose={onClose} open={open}>
      <Typography maxWidth={400}>
        Please check{' '}
        <Box component="span" color="grey.80">
          {email}
        </Box>{' '}
        for a password reset link.
      </Typography>
    </BaseModal>
  );
}
