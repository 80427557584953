import SearchIcon from '@mui/icons-material/Search';
import {
  InputAdornment,
  inputLabelClasses,
  styled,
  TextField,
} from '@mui/material';
import { ChangeEventHandler } from 'react';

const StyledSearchBar = styled(TextField)`
  & .${inputLabelClasses.standard}:not(.${inputLabelClasses.shrink}) {
    transform: translate(32px, 20px) scale(1);
  }
`;

interface IProps {
  searchValue?: string;
  searchFilter?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export function SearchBar({ searchFilter, searchValue }: IProps) {
  const shrink = searchValue ? searchValue.length > 0 : false;

  return (
    <StyledSearchBar
      label="Client name or email"
      variant="standard"
      sx={{ paddingBottom: 3 }}
      fullWidth
      value={searchValue}
      onChange={searchFilter}
      InputLabelProps={{
        shrink: shrink,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
