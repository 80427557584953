import { z } from 'zod';

import i18n from '../../config/i18n';

export function getQuoterSelectPropertySchema() {
  return z.object({
    address1: z
      .string()
      .min(1, i18n.t('validation.address.required', 'address is required')),
    address2: z.string(),
    city: z
      .string()
      .min(1, i18n.t('validation.city.required', 'city is required')),
    state: z
      .string()
      .min(1, i18n.t('validation.state.required', 'state is required')),
    zipcode: z
      .string()
      .min(1, i18n.t('validation.zipcode.required', 'zipcode is required')),
  });
}

export type IQuoterSelectPropertySchema = z.infer<
  ReturnType<typeof getQuoterSelectPropertySchema>
>;
