import { ListItemButton } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import List from '@mui/material/List';
import Fuse from 'fuse.js';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { BasicInfoCard } from '../BasicInfoCard';
import { SearchBar } from '../Inputs';

const fuseOptions = {
  keys: ['label', 'subLabel'],
};

function getFuseResults<T>(options: T[], keyword: string) {
  const fuse = new Fuse(options, fuseOptions);
  return fuse.search(keyword).map(({ item }) => item);
}

export interface IStackedListOption<T> {
  label: string;
  subLabel: string;
  value: T;
}
interface IProps<T> extends BoxProps {
  options: IStackedListOption<T>[];
  onSelected?: (value: T) => void;
  canSearch?: boolean;
  searchPlaceholder?: string;
}

export function StackedList<T>({
  options,
  onSelected,
  canSearch,
  ...boxProps
}: IProps<T>) {
  const [filteredOptions, setFilteredOptions] =
    useState<IStackedListOption<T>[]>(options);
  const [searchValue, setSearchValue] = useState('');

  const reset = useCallback(() => {
    setFilteredOptions(options);
    setSearchValue('');
  }, [options]);

  useEffect(() => {
    // reset filtered options if options change
    reset();
  }, [options, reset]);

  const searchFilter = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const keyword = event.target.value;

      if (keyword !== '') {
        const newFilteresOptionsgetFuseResults = getFuseResults<
          IStackedListOption<T>
        >(options, keyword);
        setFilteredOptions(newFilteresOptionsgetFuseResults);
        setSearchValue(keyword);
      } else {
        reset();
      }
    },
    [options, reset],
  );

  return (
    <Box {...boxProps}>
      {canSearch && (
        <SearchBar searchFilter={searchFilter} searchValue={searchValue} />
      )}
      <List disablePadding>
        {filteredOptions.map((option, index) => (
          <ListItemButton
            key={index}
            disableRipple
            disableTouchRipple
            disableGutters
            onClick={() => {
              if (onSelected) {
                onSelected(option.value);
              }
            }}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <BasicInfoCard title={option.label}>
              {option.subLabel}
            </BasicInfoCard>
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
}
