import { RouteObject } from 'react-router-dom';

import { ForgotPasswordPageContainer } from '../../pages/ForgotPassword';
import { HelpPage } from '../../pages/HelpPage';
import { LoginPageContainer } from '../../pages/LoginPage';
import { NotFoundPage } from '../../pages/NotFoundPage';
import { QuoterCustomizeCoveragePageContainer } from '../../pages/QuoterCustomizeCoveragePage';
import { QuoterDiligentSearchPageContainer } from '../../pages/QuoterDiligentSearchPage';
import { QuoterReviewPropertyDataPageContainer } from '../../pages/QuoterReviewPropertyDataPage';
import { QuoterSelectClientPageContainer } from '../../pages/QuoterSelectClientPage';
import { QuoterSelectPropertyPageContainer } from '../../pages/QuoterSelectPropertyPage';
import { QuoterUnderwritingChecksPageContainer } from '../../pages/QuoterUnderwritingChecksPage';
import { ResetPasswordPageContainer } from '../../pages/ResetPasswordPage';
import { SettingsPageContainer } from '../../pages/SettingsPage';
import { TestPage } from '../../pages/TestPage';
import { Layout } from '../Layout';

import { ProtectedRouteCheck } from './ProtectedRouteCheck';
import { UnprotectedRouteCheck } from './UnprotectedRouteCheck';

export const routes: RouteObject[] = [
  {
    // Unprotected Routes
    element: <UnprotectedRouteCheck />,
    children: [
      {
        element: <Layout type="standard" />,
        children: [
          {
            index: true,
            path: '/',
            element: <LoginPageContainer />,
          },
          {
            path: '/login',
            element: <LoginPageContainer />,
          },
          {
            path: '/forgot-password',
            element: <ForgotPasswordPageContainer />,
          },
          {
            path: '/reset-password',
            element: <ResetPasswordPageContainer />,
          },
        ],
      },
    ],
  },
  {
    // Protected Routes
    element: <ProtectedRouteCheck />,
    children: [
      {
        // Standard Routes Layout
        element: <Layout type="standard" />,
        children: [
          {
            path: '/quotes-contracts',
            element: <div>Quotes & Contracts</div>,
          },
          {
            path: '/clients',
            element: <div>Manage Clients</div>,
          },
          {
            path: '/settings',
            element: <SettingsPageContainer />,
          },
        ],
      },
      {
        // Quoter Routes Layout
        element: <Layout type="homes" />,
        children: [
          {
            path: '/quoter/select-client',
            element: <QuoterSelectClientPageContainer />,
          },
          {
            path: '/quoter/select-property',
            element: <QuoterSelectPropertyPageContainer />,
          },
          {
            path: '/quoter/diligent-search',
            element: <QuoterDiligentSearchPageContainer />,
          },
          {
            path: '/quoter/underwriting-checks',
            element: <QuoterUnderwritingChecksPageContainer />,
          },
          {
            path: '/quoter/propery-details',
            element: <QuoterReviewPropertyDataPageContainer />,
          },
          {
            path: '/quoter/customize-coverage',
            element: <QuoterCustomizeCoveragePageContainer />,
          },
        ],
      },
    ],
  },

  {
    // No protected or unprotected routes checks w/ standard layout
    element: <Layout type="standard" />,
    children: [
      {
        path: '/test',
        element: <TestPage />,
      },
      {
        path: '/help',
        element: <HelpPage />,
      },
      {
        // Not Found
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
];
