import { createTheme } from '@mui/material';

const gradients = {
  main: 'linear-gradient(0deg, rgba(34, 102, 98, 0.06), rgba(34, 102, 98, 0.06)), linear-gradient(180deg, #F1F8F7 0%, #E2F3E9 100%)',
};

export const baseTheme = createTheme({
  palette: {
    action: {
      disabledBackground: '#afafaf',
      disabled: '#afafaf',
    },
    gradient: {
      main: gradients.main,
    },
    primary: {
      main: '#2BB047',
    },
    secondary: {
      main: '#0D1329',
    },
    success: {
      main: '#2BB047',
      light: '#91CBEC',
    },
    grey: {
      '00': '#FFFFFF',
      10: '#FAFAFA',
      20: '#EAF2F0',
      30: '#DBE1E0',
      40: '#C7D1D1',
      50: '#76BEBE',
      60: '#6F8A90',
      70: '#647C82',
      80: '#3F5F69',
      90: '#2D313E',
      100: '#0D1329',
    },
  },
});
