import { z } from 'zod';

import i18n from '../../config/i18n';

export function getQuoterSelectClientSchema() {
  return z.object({
    firstName: z
      .string()
      .min(
        1,
        i18n.t('validation.firstName.required', 'first name is required'),
      ),
    lastName: z
      .string()
      .min(1, i18n.t('validation.lastName.required', 'last name is required')),
    email: z
      .string()
      .email(i18n.t('validation.email.valid', 'valid email is required'))
      .min(1, i18n.t('validation.email.required', 'email is required')),
    phone: z
      .string()
      .min(1, i18n.t('validation.phone.required', 'phone is required')),
  });
}

export type IQuoterSelectClientSchema = z.infer<
  ReturnType<typeof getQuoterSelectClientSchema>
>;
