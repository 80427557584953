import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../../components/App/PageTitle';
import { ButtonLink } from '../../components/ButtonLink';
import { QuoteStepperContainer } from '../../components/QuoteStepper/QuoteStepper.container';

export function QuoterReviewPropertyDataPage() {
  const { t } = useTranslation();
  return (
    <Box>
      <PageTitle
        title={t('pages.reviewPropertyDataPage.title', 'Review Property Data')}
      >
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
        illo inventore veritatis et quasi.
      </PageTitle>
      <Paper
        sx={{
          margin: 'auto',
          maxWidth: '960px',
          padding: 4,
        }}
      >
        <Typography fontSize="1.0625rem" fontWeight={600} mb={2} variant="h2">
          Category Title
        </Typography>
      </Paper>
      <QuoteStepperContainer backText={t('pages.underwritingChecksPage.title')}>
        <ButtonLink
          to="/quoter/customize-coverage"
          color="secondary"
          variant="contained"
        >
          Looks correct, proceed
        </ButtonLink>
      </QuoteStepperContainer>
    </Box>
  );
}
