import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../../components/App/PageTitle';
import { Button } from '../../components/Button';
import { ControllerTextField } from '../../components/Inputs';
import { PhoneInputMask } from '../../components/Inputs/TextField/PhoneInputMask';
import { TwoPanelPaper } from '../../components/Paper';
import { QuoteStepperContainer } from '../../components/QuoteStepper/QuoteStepper.container';
import { IStackedListOption, StackedList } from '../../components/StackedList';
import { User } from '../../graphql/generated';

import {
  getQuoterSelectClientSchema,
  IQuoterSelectClientSchema,
} from './QuoterSelectClientPage.schema';

interface IProps {
  clientOptionsLoading?: boolean;
  clientOptions: IStackedListOption<User>[]; // todo udate with correct type
  onCancel?: () => void;
  initialValues?: IQuoterSelectClientSchema;
  onSubmit: (values: IQuoterSelectClientSchema) => void;
}

export function QuoterSelectClientPage({
  initialValues,
  clientOptions,
  clientOptionsLoading,
  onCancel,
  onSubmit,
}: IProps) {
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm<IQuoterSelectClientSchema>({
    defaultValues: {
      email: initialValues?.email || '',
      firstName: initialValues?.firstName || '',
      lastName: initialValues?.lastName || '',
      phone: initialValues?.phone || '',
    },
    resolver: zodResolver(getQuoterSelectClientSchema()),
  });

  const onBackEventHandler = useCallback(() => {
    if (onCancel) {
      onCancel();
    }

    reset({
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    });
  }, [onCancel, reset]);

  // reset form on property click
  // set new fields to clicked property
  const onClientSelectedHandler = useCallback(
    (value: User) => {
      console.log('onClientSelectedHandler', value);

      reset({
        email: value.email,
        firstName: value.profile?.firstName || '',
        lastName: value.profile?.lastName || '',
        phone: value.profile?.phone || '',
      });
    },
    [reset],
  );

  return (
    <Box>
      <PageTitle
        title={t('pages.quoterSelectClientPage.title', 'Add Your Client')}
      />
      {/* Panel */}
      <TwoPanelPaper
        sx={{
          margin: 'auto',
          maxWidth: '960px',
        }}
        leftChildren={
          <Box margin="auto" maxWidth="448px">
            <Typography variant="h2">
              {t(
                'pages.quoterSelectClientPage.leftPanelTitle',
                'Add Your Client',
              )}
            </Typography>
            <Box mt={4} component="form" onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing={1}>
                <ControllerTextField
                  control={control}
                  name="firstName"
                  label={t('common.labels.firstName', 'First name')}
                />
                <ControllerTextField
                  control={control}
                  name="lastName"
                  label={t('common.labels.lastName', 'Last name')}
                />
                <ControllerTextField
                  control={control}
                  name="email"
                  label={t('common.labels.email', 'Email')}
                />
                <ControllerTextField
                  control={control}
                  name="phone"
                  label={t('common.labels.phone', 'Phone number')}
                  InputProps={{
                    inputComponent: PhoneInputMask as any,
                  }}
                />
              </Stack>
              <Box mt={3} textAlign="right">
                <Button color="primary" type="submit" variant="contained">
                  {t('common.actions.saveContinue', 'Save and Continue')}
                </Button>
              </Box>
            </Box>
          </Box>
        }
        rightChildren={
          <Box>
            <Typography variant="h2">
              {t(
                'pages.quoterSelectClientPage.rightPanelTitle',
                '{{name}} properties',
                { name: "Rebecca's " },
              )}
            </Typography>
            <Box mt={1}>
              {!clientOptionsLoading && (
                <StackedList
                  canSearch
                  onSelected={onClientSelectedHandler}
                  options={clientOptions}
                />
              )}
              {clientOptionsLoading && (
                <Box>
                  <Skeleton height={80} width="100%" />
                  <Skeleton height={80} width="100%" />
                  <Skeleton height={80} width="100%" />
                </Box>
              )}
            </Box>
          </Box>
        }
      />
      {/* Footer */}
      <QuoteStepperContainer backText={null} onBack={onBackEventHandler} />
    </Box>
  );
}
